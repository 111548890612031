import { computed } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/shared/stores";
import { getAppByPath } from "@/shared/helper/app.helper";
import { usePermission } from "@/shared/composables";
import { AppRouteNames } from "@/app/shared/@types";
import { RoleActions } from "@/shared/@types";
import { routes } from "./routes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  // if redirected to general error or maintenance or cookies just let it through
  if (to.name === AppRouteNames.GeneralError) return true;
  if (to.name === AppRouteNames.Cookies) return true;
  if (to.name === AppRouteNames.Maintenance) return true;

  const appToVisit = getAppByPath(to.path);

  // this should only occur if the user has a typo in the url or something
  if (!appToVisit) return { name: AppRouteNames.Dashboard };

  const accountStore = useAccountStore();

  // if no user available fetch it and set a initial customer directly
  if (!accountStore.user) {
    await accountStore.fetchUser();

    if (accountStore.fetchingUserError) {
      return { name: AppRouteNames.GeneralError };
    }

    const initialCustomerId =
      accountStore.getInitialSelectedCustomerId(appToVisit);
    accountStore.setSelectedCustomerId(initialCustomerId);
  }

  const { sapCustomerExists } = storeToRefs(accountStore);
  const { hasPermissionForApp, hasPermission } = usePermission();

  const needsToFinishRegistration = computed(
    () =>
      !sapCustomerExists.value &&
      hasPermission(RoleActions.ACCESS_FLEET_AT_HOME_APP),
  );

  const routesAccessibleWithoutCustomer: Array<string> = [
    AppRouteNames.FinishRegistration,
    AppRouteNames.ThgQuoteNotFound,
    AppRouteNames.ThgQuoteOverview,
    AppRouteNames.ThgQuoteVehicleApplications,
    AppRouteNames.ThgQuoteVehicleRegistration,
  ];

  if (
    needsToFinishRegistration.value &&
    typeof to.name === "string" &&
    !routesAccessibleWithoutCustomer.includes(to.name)
  ) {
    return {
      name: AppRouteNames.FinishRegistration,
      query: { returnTo: to.fullPath },
    };
  }

  if (
    to.name === AppRouteNames.FinishRegistration &&
    !needsToFinishRegistration.value
  ) {
    return { name: AppRouteNames.Dashboard };
  }

  if (!hasPermissionForApp(appToVisit)) {
    // if the user has no permission for the app and comes from wid-services
    // try to set a customer id that has permission
    if (from.name === AppRouteNames.WestfalenIdAccountManagementServices) {
      const desiredCustomerId =
        accountStore.getInitialSelectedCustomerId(appToVisit);
      if (!desiredCustomerId) return { name: AppRouteNames.Dashboard };
      accountStore.setSelectedCustomerId(desiredCustomerId);
    } else {
      return { name: AppRouteNames.Dashboard };
    }
  }

  return true;
});

export default router;
