import { AppRouteNames } from "@/app/shared/@types";
import type { RouteLocationNormalizedLoaded } from "vue-router";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const CardsOverviewView = () =>
  import(
    "@/app/wsc-management-app/cards-overview/CardsOverviewView/CardsOverviewView.vue"
  );
const TransactionsView = () =>
  import(
    "@/app/wsc-management-app/transactions/TransactionsView/TransactionsView.vue"
  );
const InvoicesView = () =>
  import("@/app/wsc-management-app/invoices/InvoicesView/InvoicesView.vue");

export const routes = [
  {
    path: "",
    name: AppRouteNames.WscCardsOverview,
    meta: {
      titleKey: "wscManagement.menu.cardsOverview",
    },
    props: (route: RouteLocationNormalizedLoaded) => ({
      cardQueryNr: route.query.cardNr,
    }),
    component: CardsOverviewView,
  },
  {
    path: "invoices",
    name: AppRouteNames.WscInvoices,
    meta: {
      titleKey: "wscManagement.menu.invoices",
    },
    component: InvoicesView,
  },
  {
    path: "transactions",
    name: AppRouteNames.WscTransactions,
    meta: {
      titleKey: "wscManagement.menu.transactions",
    },
    props: (route: RouteLocationNormalizedLoaded) => ({
      transactionQueryNr: route.query.transactionNr,
      searchQueryParam: route.query.search,
    }),
    component: TransactionsView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.WscNotFound,
    meta: {
      titleKey: "shared.error.page.notFoundError.title",
    },
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.WscCardsOverview,
    },
  },
];
