export enum AppRouteNames {
  AccountChangeBankDetails = "account-change-bank-details",
  AccountChangeEmail = "account-change-email",
  AccountChangePassword = "account-change-password",
  AccountChangeProfile = "account-change-profile",
  AccountDeleteAccount = "account-delete-account",
  AccountNotFound = "account-not-found",
  AccountOverview = "account-overview",
  Cookies = "cookies",
  Dashboard = "dashboard",
  DashboardNotFound = "dashboard-not-found",
  FinishRegistration = "finish-registration",
  FinishRegistrationNotFound = "finish-registration-not-found",
  FleetAtHomeCharges = "fleet-at-home-charges",
  FleetAtHomeCredits = "fleet-at-home-credits",
  FleetAtHomeManagementNotFound = "fleet-at-home-management-not-found",
  FleetAtHomeManagementUsers = "fleet-at-home-management-users",
  FleetAtHomeNotFound = "fleet-at-home-not-found",
  FleetAtHomeShop = "fleet-at-home-shop",
  FleetAtHomeShopProduct = "fleet-at-home-shop-product",
  FleetAtHomeStations = "fleet-at-home-stations",
  GeneralError = "general-error",
  Maintenance = "maintenance",
  ThgQuoteNotFound = "thg-quote-not-found",
  ThgQuoteOverview = "thg-quote-overview",
  ThgQuoteVehicleApplications = "thg-quote-vehicle-applications",
  ThgQuoteVehicleRegistration = "thg-quote-vehicle-registration",
  WccCardsArchive = "wcc-management-cards-archive",
  WccCardsOverview = "wcc-management-cards-overview",
  WccCharges = "wcc-management-charges",
  WccInvoices = "wcc-management-invoices",
  WccNotFound = "wcc-management-not-found",
  WccOrders = "wcc-management-orders",
  WestfalenIdAccountManagementOverview = "westfalen-id-account-management-overview",
  WestfalenIdAccountManagementUsers = "westfalen-id-account-management-users",
  WestfalenIdAccountManagementServices = "westfalen-id-account-management-services",
  WestfalenIdAccountNotFound = "westfalen-id-account-not-found",
  WscCardsOverview = "wsc-management-cards-overview",
  WscInvoices = "wsc-management-invoices",
  WscNotFound = "wsc-management-not-found",
  WscTransactions = "wsc-management-transactions",
}
