import { AppRouteNames } from "@/app/shared/@types";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const DashboardView = () =>
  import("@/app/dashboard/dashboard/DashboardView/DashboardView.vue");

export const routes = [
  {
    path: "",
    name: AppRouteNames.Dashboard,
    component: DashboardView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.DashboardNotFound,
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.Dashboard,
    },
  },
];
