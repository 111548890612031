import { AppRouteNames } from "@/app/shared/@types";

const StationsView = () =>
  import("@/app/fleet-at-home-app/stations/StationsView/StationsView.vue");
const ChargesView = () =>
  import("@/app/fleet-at-home-app/charges/ChargesView/ChargesView.vue");
const CreditsView = () =>
  import("@/app/fleet-at-home-app/credits/CreditsView/CreditsView.vue");
const ShopView = () =>
  import("@/app/fleet-at-home-app/shop/ShopView/ShopView.vue");
const ShopProductView = () =>
  import("@/app/fleet-at-home-app/shop/product/ProductView/ProductView.vue");
const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");

export const routes = [
  {
    path: "",
    redirect: { name: AppRouteNames.FleetAtHomeStations },
  },
  {
    path: "stations",
    name: AppRouteNames.FleetAtHomeStations,
    meta: {
      titleKey: "fleetAtHome.stations.title",
    },
    component: StationsView,
  },
  {
    path: "charges",
    name: AppRouteNames.FleetAtHomeCharges,
    meta: {
      titleKey: "fleetAtHome.charges.title",
    },
    component: ChargesView,
  },
  {
    path: "credits",
    name: AppRouteNames.FleetAtHomeCredits,
    meta: {
      titleKey: "fleetAtHome.credits.title",
    },
    component: CreditsView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.FleetAtHomeNotFound,
    meta: {
      titleKey: "shared.error.page.notFoundError.title",
    },
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.FleetAtHomeStations,
    },
  },
  {
    path: "shop",
    name: AppRouteNames.FleetAtHomeShop,
    meta: {
      titleKey: "fleetAtHome.shop.title",
    },
    component: ShopView,
  },
  {
    path: "shop/:productId",
    name: AppRouteNames.FleetAtHomeShopProduct,
    component: ShopProductView,
    props: true,
  },
];
