import { AppRouteNames } from "@/app/shared/@types";
import type { RouteLocationNormalizedLoaded } from "vue-router";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const UsersView = () =>
  import("@/app/wid-account-management-app/users/UsersView/UsersView.vue");
const OverviewView = () =>
  import(
    "@/app/wid-account-management-app/overview/OverviewView/OverviewView.vue"
  );
const ServicesView = () =>
  import(
    "@/app/wid-account-management-app/services/ServicesView/ServicesView.vue"
  );

export const routes = [
  {
    path: "",
    name: AppRouteNames.WestfalenIdAccountManagementOverview,
    component: OverviewView,
  },
  {
    path: "users",
    name: AppRouteNames.WestfalenIdAccountManagementUsers,
    props: (route: RouteLocationNormalizedLoaded) => ({
      userQueryId: route.query.userId,
    }),
    component: UsersView,
  },
  {
    path: "services",
    name: AppRouteNames.WestfalenIdAccountManagementServices,
    component: ServicesView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.WestfalenIdAccountNotFound,
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.WestfalenIdAccountManagementUsers,
    },
  },
];
