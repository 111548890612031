import "@/shared/styles/design-system/setup.css";
import "@/shared/styles/setup.scss";
import "@/shared/styles/globals.css";
import { createApp } from "vue";
import { initSentry } from "@/shared/sentry/sentry";
import { usePlugins } from "@/shared/plugins/usePlugins";
import { registerAllComponents } from "@zweitag/design-system";
import App from "./App.vue";
import plugins from "./plugins";

registerAllComponents();

const app = createApp(App);

usePlugins(app, plugins);
initSentry(app);

app.mount("#app");
