import { AppRouteNames } from "@/app/shared/@types";
import type { RouteLocationNormalizedLoaded } from "vue-router";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const CardsOverviewView = () =>
  import(
    "@/app/wcc-management-app/cards-overview/CardsOverviewView/CardsOverviewView.vue"
  );
const ChargeHistoryView = () =>
  import(
    "@/app/wcc-management-app/charge-history/ChargeHistoryView/ChargeHistoryView.vue"
  );
const OrdersView = () =>
  import("@/app/wcc-management-app/orders/OrdersView/OrdersView.vue");
const InvoicesView = () =>
  import("@/app/wcc-management-app/invoices/InvoicesView/InvoicesView.vue");
const CardsArchiveView = () =>
  import(
    "@/app/wcc-management-app/cards-archive/CardsArchiveView/CardsArchiveView.vue"
  );

export const routes = [
  {
    path: "",
    name: AppRouteNames.WccCardsOverview,
    meta: {
      titleKey: "wccManagement.menu.cardsOverview",
    },
    props: (route: RouteLocationNormalizedLoaded) => ({
      cardQueryNr: route.query.cardNr,
    }),
    component: CardsOverviewView,
  },
  {
    path: "charges",
    name: AppRouteNames.WccCharges,
    meta: {
      titleKey: "wccManagement.menu.charges",
    },
    component: ChargeHistoryView,
  },
  {
    path: "orders",
    name: AppRouteNames.WccOrders,
    meta: {
      titleKey: "wccManagement.menu.orders",
    },
    component: OrdersView,
  },
  {
    path: "invoices",
    name: AppRouteNames.WccInvoices,
    meta: {
      titleKey: "wccManagement.menu.invoices",
    },
    component: InvoicesView,
  },
  {
    path: "cards-archive",
    name: AppRouteNames.WccCardsArchive,
    meta: {
      titleKey: "wccManagement.menu.cardsArchive",
    },
    props: (route: RouteLocationNormalizedLoaded) => ({
      cardQueryNr: route.query.cardNr,
    }),
    component: CardsArchiveView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.WccNotFound,
    meta: {
      titleKey: "shared.error.page.notFoundError.title",
    },
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.WccCardsOverview,
    },
  },
];
