import { AppRouteNames } from "@/app/shared/@types";
import type { RouteLocationNormalizedLoaded } from "vue-router";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const UsersView = () =>
  import("@/app/fleet-at-home-management-app/users/UsersView/UsersView.vue");

export const routes = [
  {
    path: "",
    redirect: { name: AppRouteNames.FleetAtHomeManagementUsers },
  },
  {
    path: "users",
    name: AppRouteNames.FleetAtHomeManagementUsers,
    meta: {
      titleKey: "fleetAtHomeManagement.users.title",
    },
    props: (route: RouteLocationNormalizedLoaded) => ({
      userQueryId: route.query.userId,
    }),
    component: UsersView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.FleetAtHomeManagementNotFound,
    meta: {
      titleKey: "shared.error.page.notFoundError.title",
    },
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.FleetAtHomeManagementUsers,
    },
  },
];
