import { storeToRefs } from "pinia";
import type { AppIdentifier, RoleActions } from "@/shared/@types";
import { useAccountStore } from "@/shared/stores/AccountStore";
import {
  checkPermissions,
  checkPermissionsByAppIdentifier,
} from "../helper/permissions.helper";

export function usePermission() {
  const accountStore = useAccountStore();
  const { userRoles } = storeToRefs(accountStore);

  return {
    hasPermission: (action: RoleActions) =>
      checkPermissions(action, userRoles.value),
    hasPermissionForApp: (appIdentifier: AppIdentifier) =>
      checkPermissionsByAppIdentifier(appIdentifier, userRoles.value),
  };
}
