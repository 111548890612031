import { AppRouteNames } from "@/app/shared/@types";
import { useAccountStore } from "@/shared/stores";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const OverviewView = () =>
  import("@/app/account-app/overview/OverviewView/OverviewView.vue");
const ChangeEmailView = () =>
  import("@/app/account-app/change-email/ChangeEmailView/ChangeEmailView.vue");
const ChangePasswordView = () =>
  import(
    "@/app/account-app/change-password/ChangePasswordView/ChangePasswordView.vue"
  );
const ChangeProfileView = () =>
  import(
    "@/app/account-app/change-profile/ChangeProfileView/ChangeProfileView.vue"
  );
const ChangeBankDetailsView = () =>
  import(
    "@/app/account-app/change-bank-details/ChangeBankDetailsView/ChangeBankDetailsView.vue"
  );
const DeleteAccountView = () =>
  import(
    "@/app/account-app/delete-account/DeleteAccountView/DeleteAccountView.vue"
  );

export const routes = [
  {
    path: "",
    name: AppRouteNames.AccountOverview,
    component: OverviewView,
  },
  {
    path: "change-email",
    name: AppRouteNames.AccountChangeEmail,
    component: ChangeEmailView,
  },
  {
    path: "change-password",
    name: AppRouteNames.AccountChangePassword,
    component: ChangePasswordView,
  },
  {
    path: "change-profile",
    name: AppRouteNames.AccountChangeProfile,
    component: ChangeProfileView,
  },
  {
    path: "change-bank-details",
    name: AppRouteNames.AccountChangeBankDetails,
    component: ChangeBankDetailsView,
    beforeEnter: () => {
      const accountStore = useAccountStore();
      if (!accountStore.canStoreBankDetails)
        return AppRouteNames.AccountNotFound;

      return true;
    },
  },
  {
    path: "delete-account",
    name: AppRouteNames.AccountDeleteAccount,
    component: DeleteAccountView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.AccountNotFound,
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.AccountOverview,
    },
  },
];
