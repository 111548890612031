import { AppRouteNames } from "@/app/shared/@types";
import type { RouteLocationNormalizedLoaded } from "vue-router";

const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");
const FinishRegistrationView = () =>
  import(
    "@/app/finish-registration-app/finish-registration/FinishRegistrationView/FinishRegistrationView.vue"
  );

export const routes = [
  {
    path: "",
    name: AppRouteNames.FinishRegistration,
    component: FinishRegistrationView,
    props: (route: RouteLocationNormalizedLoaded) => ({
      returnTo: route.query.returnTo,
    }),
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.FinishRegistrationNotFound,
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.FinishRegistration,
    },
  },
];
